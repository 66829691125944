<script setup>
const props = defineProps({
  filter: {
    type: Object,
    required: true
  },
  filtersStore: {
    type: Object,
    required: true
  },
  useSlug: {
    type: Boolean,
    default: false
  },
  showInputLabel: {
    type: Boolean,
    default: false,
  }
})

const value = ref('turkey');
const value_string = ref('Turkey');
const selected_option = ref(
  props.filtersStore.selected_filters[props.filter.slug]['selected_neighborhood'] ||
  props.filtersStore.selected_filters[props.filter.slug]['selected_district'] ||
  props.filtersStore.selected_filters[props.filter.slug]['selected_city'] ||
  {
    name: props.filtersStore.selected_filters[props.filter.slug]?.selected_city || [],
    url: props.filtersStore.selected_filters[props.filter.slug]?.value || 'turkey',
    top_parent: '',
  });
const currentLocation = ref(props.filtersStore.currentLocation);

const cities = ref([])
const search = async (q = '') => {


  let url = `/api/data/locations?term=${q}`;
  if (props.filtersStore?.customQuery?.location) {
    const queryString = Object.entries(props.filtersStore.customQuery.location)
      .map(([key, value]) => `${key}=${value}`).join('&');
    url += `&${queryString}`;
  }

  cities.value = await $fetch(url);
  return cities;
};
search("")// to get all cities

const onSelect = (val) => {

  value.value = props.useSlug ? val.slug : (val.url || 'turkey');
  value_string.value = val.name || 'Turkey';
  props.filtersStore.updateValue(props.filter, {
    label_value: value_string.value,
    slug: value.value,
  });
};
</script>

<template>
  <div class="flex flex-col">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal mb-3">{{ filter.name }}</h3>
    <w-input :select="true" :options="cities" v-model:value="selected_option" type="select" name="city"
      :placeholder="currentLocation || selected_option.name || 'Enter Location'" option-attribute="name" trailing
      trackBy="_id" label="name" @update:item="onSelect" class="mb-3 me-2" />
  </div>
</template>